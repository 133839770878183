.App {
  text-align: center;
}

.navbar {
  top: 0;
  left: 0;
  width: 100%;
  background-color: white; /* White background for the navbar */
  z-index: 10; /* Ensure it's above other content */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional: Adds subtle shadow for separation */
}

.navbar .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

.navbar img {
  max-width: 50px;  /* Limit the logo size */
  height: auto;
}

.navbar a {
  color: #333;
  text-decoration: none;
  font-size: 1.1rem;
  padding: 10px;
  transition: background-color 0.3s;
}

.navbar a:hover {
  background-color: #61dafb;
  border-radius: 5px;
}

.hero-section {
  background-image: url('../public/city.png');  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: 100vh; /* You can adjust this based on your preference */
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  overflow: hidden;
  padding-top: 60px;  /* Ensures the content doesn't go under the navbar */
}

.hero-section .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Dark overlay to increase contrast */
  z-index: 1;
}

.hero-section .container {
  z-index: 2;
  position: relative;
}

.text-shadow {
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adds a shadow to text for better readability */
  color: white;
}

h1, p {
  margin: 0;
  padding: 0;
  font-size: 2rem;
}

h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

p {
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
}

.btn-primary {
  padding: 10px 20px;
  font-size: 1.1rem;
  background-color: #61dafb;
  color: #000;
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.btn-primary:hover {
  background-color: #45a3d7;
}

/* Services Section */
.services-page {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.services-page h2 {
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.services-page p {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 40px;
}

.services-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 30px;
  justify-content: center;  /* Centering the grid */
  max-width: 1400px; /* Ensures no more than 5 cards are displayed */
  margin: 0 auto; /* Centers the container */
}

.service-item {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.service-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
}

.service-item h3 {
  font-size: 1.8rem;
  color: #0077b5;
  margin-bottom: 15px;
}

.service-item p {
  font-size: 1rem;
  color: #555;
}

.service-item .text-center {
  margin-top: 20px;
}

button {
  padding: 12px 25px;
  background-color: #0077b5;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #005f83;
}

.text-center {
  text-align: center;
}


/* Contact Section */
.contact-section {
  padding: 60px 0;
  background-color: #f9f9f9;
}

.contact-section h2 {
  margin-bottom: 30px;
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
}

.contact-card {
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
  overflow: hidden;
  background-color: white;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.contact-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Slight lift effect on hover */
}

.profile-image {
  width: 120px;
  height: 120px;
  border-radius: 50%;  /* Makes the image circular */
  object-fit: cover;
  margin: 20px auto; /* Centering the image */
}

.card-body {
  text-align: center;
  padding: 20px;
}

.card-body a {
  text-decoration: none;
  color: #0077b5; /* LinkedIn color */
  font-weight: bold;
  transition: color 0.3s ease;
}

.card-body a:hover {
  color: #005f83; /* Slightly darker color on hover */
}

.card-body p {
  font-size: 1.1rem;
  color: #555;
  margin-bottom: 10px;
}

.card-body p a {
  color: inherit;
}

.card-body p a:hover {
  color: inherit;
}


footer {
  padding: 2rem 0;
  background-color: #282c34;
  color: white;
}

footer p {
  margin: 0;
}

@media (max-width: 768px) {
  .hero-section .container h1 {
    font-size: 2.5rem;
  }

  .hero-section .container p {
    font-size: 1.1rem;
  }

  .btn-primary {
    font-size: 1rem;
  }

  .services-page h2 {
    font-size: 2rem;
  }

  .services-page p {
    font-size: 1rem;
  }

  .contact-section h2 {
    font-size: 1.5rem;
  }

  .contact-section .contact-card {
    padding: 1.5rem;
  }
}